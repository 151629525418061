import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Importing AOS styles

// Importing components
import Header from './Components/Header';
import Home from './Components/Home';
import About from './Components/About';
import Services from './Components/Services';
import OurVoice from './Components/OurVoice';
import Footer from './Components/Footer';
import MediaCorner from './Components/MediaCorner';
import CompanyLogo from './Components/CompanyLogos';
import ContactUs from './Components/Contact';
import Founders from './Components/Founders';
import WhyUs from './Components/WhyUs';
import FloatingButtons from './Components/FloatingButtons';
import ProgressBar from './Components/ProgressBar';
import Gallery from './Components/Gallery';

function App() {
  // Initialize AOS animations on mount
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: 'ease-in-out', // Easing function
      once: true, // Trigger animation only once when the element is in view
    });

    // Cleanup AOS to refresh animations when components are unmounted
    return () => {
      AOS.refresh();
    };
  }, []);

  return (
    <div>
      <ProgressBar/>
      <Header />
      <Home />
      <FloatingButtons />
      <About />
      <Services />
      <OurVoice />
      <Gallery />
      <Founders />
      <CompanyLogo />
      <MediaCorner />
      <WhyUs />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
