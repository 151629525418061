import React from 'react';
import Slider from 'react-slick';  // Assuming you're using a carousel library like react-slick

const Carousel = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {images.map((image, index) => (
          <a key={index} href={image.link} target="_blank" rel="noopener noreferrer">
            <img
              src={image.src}
              alt={image.alt}
              className="w-full h-auto rounded-xl shadow-lg transform transition duration-300 hover:scale-105"
            />
          </a>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
