import React, { useState } from 'react';
import { useIntersection } from '../hooks/useIntersection';
import mapmark from '../Assests/Assets/mapmark.png';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  // State for active link in 'Visit Us'
  const [activeLink, setActiveLink] = useState(null);

  // Intersection observer hook for animation visibility
  const [ref, isVisible] = useIntersection({ threshold: 0.1 });

  // Links for the 'Visit Us' section
  const visitUsLinks = [
    { name: 'ChakraGroup', url: 'https://www.chakrainfotainment.com/' },
    { name: 'Joy Bharath', url: 'https://www.joybharath.com/' },
    { name: 'Algo Works', url:  'https://www.algoworks.co.in/' },
    { name: 'Amigo Dolls', url: 'https://media.istockphoto.com/id/1411798446/vector/modern-coming-soon-under-construction-sticker-banner.jpg?s=612x612&w=0&k=20&c=KgQ9eYEfuzTdmc5ypmMYoz8JHPTbe_TjEXxyxNixKQ4=' },
  ];

  // Handle form field changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic Validation
    if (!formData.name || !formData.mobile || !formData.email || !formData.message) {
        alert('Please fill out all fields.');
        return;
    }

    if (!/\S+@\S+\.\S+/.test(formData.email)) {
        alert('Please enter a valid email address.');
        return;
    }

    const formDataToSend = new URLSearchParams();
    formDataToSend.append('USER_NAME', formData.name);
    formDataToSend.append('MOBILE', formData.mobile);
    formDataToSend.append('EMAIL_ID', formData.email);
    formDataToSend.append('MESSAGE', formData.message);

    setIsSubmitting(true);  // Set loading state to true

    try {
        const response = await fetch('https://www.chakragroup.in/contact.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formDataToSend.toString(),
        });

        if (!response.ok) {
            throw new Error('Failed to send message');
        }

        const result = await response.text();  // Handle plain text response
        alert('Message sent successfully!'); // Show success message

        // Reset form fields after successful submission
        setFormData({
            name: '',
            mobile: '',
            email: '',
            message: '',
        });
    } catch (error) {
        alert(`Error: ${error.message}`); // Show error message
    } finally {
        setIsSubmitting(false);  // Set loading state to false after completion
    }
};

  

  // Handle active link in 'Visit Us'
  const handleClick = (link) => {
    setActiveLink(link);
  };

  return (
    <div
      ref={ref}
      className={`p-4 bg-blue-300 transform transition-all duration-1000 ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-50 translate-y-5'
      }`}
      id="contact"
    >
      <div className="md:p-32">
        <div className="md:grid grid-cols-3 gap-20 text-black">
          {/* Contact Form */}
          <form className="col-span-1" onSubmit={handleSubmit}>
            <h1 className="text-xl font-bold">Write Us</h1>
            <div className="grid gap-4 mt-4">
              <input
                id="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-2 border-b focus:outline-none"
              />
              <input
                id="mobile"
                placeholder="Mobile"
                value={formData.mobile}
                onChange={handleChange}
                className="w-full p-2 border-b focus:outline-none"
              />
              <input
                id="email"
                placeholder="Email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 border-b focus:outline-none"
              />
              <textarea
                id="message"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
                rows="3"
                className="w-full p-2 border-b focus:outline-none"
              ></textarea>
          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full py-2 px-4 border-2 border-blue-500 bg-blue-500 text-white rounded hover:bg-gray-700 transition-all duration-300"
          >
            {isSubmitting ? 'Sending...' : 'Submit'}
         </button>
         </div>
          </form>

          {/* Meet Us Section */}
          <div className="col-span-1 text-xl">
            <h1 className="font-bold">Meet Us</h1>
            <p className="mt-4">403, Alcazar Plaza, Rd Number 1, Hyderabad</p>
            <a
              href="https://maps.google.com?q=403,+Alcazar+Plaza,+Rd+Number+1"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
            >
              View on Map
            </a>
          </div>

          {/* Visit Us Section */}
          <div className="col-span-1 text-xl">
            <h1 className="font-bold">Visit Us</h1>
            {visitUsLinks.map((link, idx) => (
              <a
                key={idx}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className={`block hover:underline ${
                  activeLink === link.name ? 'text-blue-500' : ''
                }`}
                onClick={() => handleClick(link.name)}
                style={{ cursor: 'pointer' }} // Add pointer cursor
              >
                {link.name}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
