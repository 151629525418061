import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import backVideo from '../Assests/Assets/Back.mp4';
import backImage from '../Assests/Assets/backImage.png';
import { useIntersection } from '../hooks/useIntersection';

function Home() {
  const [ref, isVisible] = useIntersection({ threshold: 0.1 });

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
    });
  }, []);

  return (
    <div id="home">
      <div
        ref={ref}
        className={`transition-opacity duration-1000 ease-in-out ${
          isVisible ? 'opacity-100' : 'opacity-0'
        }`}
        data-aos="fade-down"
      ></div>

      <video
        src={backVideo}
        autoPlay
        loop
        muted
        className="hidden sm:block w-full h-full object-cover"
        data-aos="fade-up"
      ></video>

      <img
        src={backImage}
        alt="Background"
        className="block sm:hidden w-full h-full object-cover mt-10"
        data-aos="fade-up"
      />
    </div>
  );
}

export default Home;
