import React from 'react';
import { faYoutube, faInstagram, faLinkedin, faFacebook, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = () => {
  return (
    <>
      <footer className="bg-blue-950">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="grid sm:grid-cols-12 gap-8 py-16 md:py-20 border-t-0 border-gray-200">
            <div className="sm:col-span-12 lg:col-span-3">
              <h2 className="text-3xl text-white font-bold -mx-8">Chakra Group</h2>
              <address className="mt-3 text-white not-italic">
                <div className="flex items-center -mx-8">
                  <svg className="w-8 h-8 fill-current mr-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" d="M0 0h24v24H0V0z" />
                    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
                  </svg>
                  <span>403, Alcazar Plaza, Road No.1, Banjarahills, Hyderabad - 500034, Telangana.</span>
                </div>
              </address>
              <div className="mt-3 text-lg text-white -mx-5">Follow Us On</div>
              <ul className="flex mb-4 mt-2 lg:-mx-12">
                {[
                  { icon: faXTwitter, color: '#FFFFFF', url: 'https://twitter.com/Chakrainfotain', label: 'Twitter' },
                  { icon: faYoutube, color: '#FF0000', url: 'https://www.youtube.com/@chakrainfotainment6507', label: 'YouTube' },
                  { icon: faInstagram, color: '#C13584', url: 'https://www.instagram.com/chakrainfotainment', label: 'Instagram' },
                  { icon: faLinkedin, color: '#0077B5', url: '', label: 'LinkedIn' },
                  { icon: faFacebook, color: '#1877F2', url: 'https://www.facebook.com/search/top?q=chakra%20infotainment', label: 'Facebook' },
                ].map((social, idx) => (
                  <li key={idx} className="mx-1">
                    <a href={social.url} target="_blank" rel="noopener noreferrer" aria-label={`Follow us on ${social.label}`}>
                      <FontAwesomeIcon icon={social.icon} size="2x" style={{ color: social.color }} />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </footer>

      {/* Footer text for rights reserved */}
      <div className="bg-blue-950 text-white py-4">
        <p className="text-center text-sm">&copy; Chakra Group 2024. All rights reserved.</p>
      </div>
    </>
  );
};

export default Footer;
