import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import founder1 from '../Assests/Assets/founder-1.jpg';
import founder3 from '../Assests/Assets/founder-3.jpg';
import { useIntersection } from '../hooks/useIntersection';

function Founders() {
  const [ref, isVisible] = useIntersection({ threshold: 0.1 });

  useEffect(() => {
    AOS.init({ duration: 1000, easing: 'ease-in-out' });
  }, []);

  const founders = [
    { name: 'Rathnam Dhaveji', role: 'CEO & MD', img: founder1 },
    { name: 'Sashi Dhaveji', role: 'Director', img: founder3 },
  ];

  return (
    <section id="founders" className="bg-gray-50 py-20 px-6 md:px-12 mt-10">
      <h1 className="text-4xl font-semibold text-center text-black mb-6">Founders</h1>
      <div
        ref={ref}
        className={`grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-16 transition-opacity duration-1000 ease-in-out ${
          isVisible ? 'opacity-100' : 'opacity-0'
        }`}
      >
        {founders.map((founder, idx) => (
          <div key={idx} className="flex flex-col items-center bg-white p-8 rounded-xl shadow-lg transform transition-transform duration-500 hover:scale-105" data-aos="fade-up">
            <img
              src={founder.img}
              alt={`Portrait of ${founder.name}`}
              className="w-32 h-32 mb-6 rounded-full object-cover border-4 border-blue-900"
            />
            <p className="text-2xl font-semibold text-blue-900 mb-2">{founder.name}</p>
            <p className="text-lg text-gray-600">{founder.role}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Founders;
