import React, { useEffect } from 'react';
import { useIntersection } from '../hooks/useIntersection';
import ceoPhoto from '../Assests/Assets/founder-1.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';

function OurVoice() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
    });
  }, []);

  const [ref, isVisible] = useIntersection({ threshold: 0.1 });

  return (
    <div
      ref={ref}
      className={`flex flex-col items-center p-6 mt-8 bg-gradient-to-r from-blue-300 to-blue-200 rounded-2xl shadow-2xl border border-gray-200 transition-transform duration-1000 ease-in-out ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
      }`}
    >
      <div className="text-center mb-6">
        <h2 className="text-4xl font-bold text-black" data-aos="fade-down">
          Our Voice
        </h2>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col md:flex-row items-center md:items-start gap-6">
        <div className="flex-1">
          <h3 className="text-3xl font-bold mb-4 text-black">
            "Empowering Innovation, Transforming Futures"
          </h3>
          <p className="text-lg text-gray-700 mb-4 leading-relaxed">
            Reflecting on our journey, I'm immensely proud of what we've achieved together...
          </p>
          <blockquote className="italic text-gray-600 border-l-4 border-pink-300 pl-4 mb-4">
            "The only way to do great work is to love what you do." – Steve Jobs
          </blockquote>
          <p className="text-lg text-gray-700 mt-4 leading-relaxed">
            Our approach is simple: combine passion with expertise, and you get results that matter...
          </p>
        </div>
        <div className="flex-shrink-0 w-full md:w-1/3">
          <img
            src={ceoPhoto}
            alt="CEO"
            className="w-full h-auto rounded-lg border-4 border-purple-300 shadow-lg"
          />
        </div>
      </div>
    </div>
  );
}

export default OurVoice;
