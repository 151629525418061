import React, { useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import img1 from '../Assests/Assets/image-1.jpg';
import img2 from '../Assests/Assets/image-2.jpg';
import img3 from '../Assests/Assets/image-3.jpg';

function Gallery() {
  useEffect(() => {
    AOS.init({ duration: 1000, easing: 'ease-in-out' });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 600, settings: { slidesToShow: 1 } },
    ],
  };

  const images = [img1, img2, img3];

  return (
    <section id="gallery" className="text-center bg-gray-100 py-10">
      <h1 className="text-3xl font-bold text-blue-900" data-aos="fade-down">
        PHOTO GALLERY
      </h1>
      <div className="mx-auto mt-10 max-w-7xl">
        <Slider {...settings}>
          {images.map((img, idx) => (
            <div key={idx} className="p-4">
              <img src={img} alt={`Gallery item ${idx + 1}`} className="w-full rounded-lg shadow-lg" />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default Gallery;
