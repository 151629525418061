import React, { useEffect } from 'react';
import { useIntersection } from '../hooks/useIntersection';
import AOS from 'aos';
import 'aos/dist/aos.css';

import chakraGroupLogo from '../Assests/Assets/ChakraLogo.png';
import amigoDollsLogo from '../Assests/Assets/AmigoDolls.png';
import joyBharathLogo from '../Assests/Assets/JoyBharath.png';
import algoWorksLogo from '../Assests/Assets/AlgoWorks.png';

function Services() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
    });
  }, []);

  const [ref, isVisible] = useIntersection({ threshold: 0.1 });

  const services = [
    {
      name: 'CHAKRA GROUP',
      description: 'We provide high-quality real estate and entertainment services...',
      logo: chakraGroupLogo,
    },
    {
      name: 'AMIGO DOLLS',
      description: 'Engaged in media production, specializing in digital and satellite content...',
      logo: amigoDollsLogo,
    },
    {
      name: 'JOY BHARATH',
      description: 'Offering premier real estate development and entertainment solutions...',
      logo: joyBharathLogo,
    },
    {
      name: 'ALGO WORKS',
      description: 'A media business creating and distributing digital, satellite...',
      logo: algoWorksLogo,
    },
  ];

  return (
    <div
      ref={ref}
      className={`text-center mb-32 p-6 transform transition-all duration-1000 ease-in-out ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
      }`}
    >
      <h1 className="text-4xl font-semibold text-black mb-6">Our Services</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mx-4 md:mx-6">
        {services.map((service, index) => (
          <div
            key={index}
            className="bg-white text-black rounded-lg shadow-lg p-6"
            data-aos="fade-up"
          >
            <div className="flex flex-col items-center">
              <img src={service.logo} alt={service.name} className="w-24 h-24 object-contain mb-4" />
              <p className="text-lg md:text-xl font-bold mt-4 mb-2">{service.name}</p>
              <p className="text-sm md:text-base text-center">{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;
