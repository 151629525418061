import React from 'react';

function WhyUs() {
  return (
    <div className="flex items-center justify-center h-[400px] bg-blue-100">
      <div className="max-w-2xl text-center">
        <h2 className="text-2xl text-blue-900 font-bold underline mb-6">
          WHY US !!
        </h2>
        <p className="mb-4 text-xl">
          As the prestigious and leading company, we are dedicated to delivering quality products...
        </p>
      </div>
    </div>
  );
}

export default WhyUs;
