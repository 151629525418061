import React, { useEffect } from 'react';
import Carousel from './Carousel';  // Assuming Carousel is set up properly
import AOS from 'aos';
import 'aos/dist/aos.css';

// Import the images
import Image1 from '../Assests/Assets/chakraweb.png';
import Image2 from '../Assests/Assets/joybharathweb.png';
import Image3 from '../Assests/Assets/algoweb.png';

function About() {
  useEffect(() => {
    AOS.init({ duration: 1000, easing: 'ease-in-out' });
  }, []);

  // Define the image links with URLs
  const imageLinks = [
    { src: Image1, link: 'https://www.chakrainfotainment.com/', alt: 'Chakrainfotainment' },
    { src: Image2, link: 'https://www.joybharath.com/', alt: 'Joy Bharath' },
    { src: Image3, link: 'https://www.algoworks.co.in/', alt: 'Algo Works' },
  ];

  return (
    <div
      className="p-6"
      id="about"
      style={{ scrollMarginTop: '80px' }}
      data-aos="fade-up"
    >
      <div className="flex flex-col md:grid grid-cols-2 gap-8">
        <div
          className="bg-gradient-to-r from-blue-200 to-blue-200 rounded-2xl shadow-2xl border border-gray-300 p-6 flex flex-col items-center"
          data-aos="fade-right"
        >
          <h1 className="text-5xl font-bold text-black mb-6 text-center">ABOUT</h1>
          <p className="text-lg text-black text-center mb-6">
            Reflecting on our journey, I'm immensely proud of what we've achieved
            together. Our company's success is a testament to your hard work,
            dedication, and innovative spirit.
          </p>
        </div>
        <div
          className="bg-white rounded-2xl shadow-lg border border-gray-300 p-6"
          data-aos="fade-left"
        >
          {/* Pass the image data to Carousel */}
          <Carousel images={imageLinks} />
        </div>
      </div>
    </div>
  );
}

export default About;
