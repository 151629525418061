// import React, { useEffect } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import AOS from 'aos';
// import 'aos/dist/aos.css';

// import {
//   faWhatsapp,
//   faLinkedin,
//   faFacebook,
//   faYoutube,
//   faXTwitter,
// } from '@fortawesome/free-brands-svg-icons';

// const FloatingButtons = () => {
//   useEffect(() => {
//     AOS.init({ duration: 1000 });
//   }, []);

//   const buttons = [
//     { href: 'https://wa.me/9390227940', icon: faWhatsapp, bg: 'bg-green-500' },
//     {
//       href: 'https://linkedin.com/in/chakra-group-46351931a',
//       icon: faLinkedin,
//       bg: 'bg-blue-700',
//     },
//     { href: 'https://x.com/Chakrainfotain', icon: faXTwitter, bg: 'bg-black' },
//     {
//       href: 'https://facebook.com/profile.php?id=100077853575857',
//       icon: faFacebook,
//       bg: 'bg-blue-800',
//     },
//     {
//       href: 'https://www.youtube.com/@chakrainfotainment6507',
//       icon: faYoutube,
//       bg: 'bg-red-600',
//     },
//   ];

//   return (
//     <div className="fixed bottom-4 right-4 flex flex-col space-y-3">
//       {buttons.map(({ href, icon, bg }, idx) => (
//         <a
//           key={idx}
//           href={href}
//           target="_blank"
//           rel="noopener noreferrer"
//           className={`${bg} p-3 rounded-full shadow-lg hover:scale-110`}
//         >
//           <FontAwesomeIcon icon={icon} className="text-white w-5 h-5" />
//         </a>
//       ))}
//     </div>
//   );
// };

// export default FloatingButtons;


import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AOS from 'aos';
import 'aos/dist/aos.css';

import {
  faWhatsapp,
  faLinkedin,
  faFacebook,
  faYoutube,
  faXTwitter,
} from '@fortawesome/free-brands-svg-icons';

const FloatingButtons = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const buttons = [
    { href: 'https://wa.me/9390227940', icon: faWhatsapp, bg: 'bg-green-500' },
    {
      href: 'https://linkedin.com/in/chakra-group-46351931a',
      icon: faLinkedin,
      bg: 'bg-blue-700',
    },
    { href: 'https://x.com/Chakrainfotain', icon: faXTwitter, bg: 'bg-black' },
    {
      href: 'https://facebook.com/profile.php?id=100077853575857',
      icon: faFacebook,
      bg: 'bg-blue-800',
    },
    {
      href: 'https://www.youtube.com/@chakrainfotainment6507',
      icon: faYoutube,
      bg: 'bg-red-600',
    },
  ];

  return (
    <div className="fixed bottom-4 right-4 flex flex-col space-y-3 z-50">
      {buttons.map(({ href, icon, bg }, idx) => (
        <a
          key={idx}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className={`${bg} p-3 rounded-full shadow-lg hover:scale-110 transition-transform`}
        >
          <FontAwesomeIcon icon={icon} className="text-white w-5 h-5" />
        </a>
      ))}
    </div>
  );
};

export default FloatingButtons;
